<template>
  <div class="about">
    <h1>See you soon!</h1>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
